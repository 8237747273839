export var lang_de = {
  language: "german",
  language_code: "de",

  button_lang: "SI/DE",
  button_tooltip_lang: "Slovenščina<br/><b>Deutsch</b>",
  button_municipalities: "Gemeinden",
  button_villages: "Dörfer",
  button_tooltip_download: "Daten herunterladen",
  button_tooltip_feedback: "Mach mit",

  placeholder_search: "Suchen",

  info_name_dialect: "Name Mundart",
  info_name_slo: "Name Schriftsprache",
  info_name_de: "Name Deutsch",
  info_audio: "Aufnahme der Ausprache in Mundart",
  info_img: "Foto",
  info_category: "Kategorie",
  info_category_key: "Kategorie ID",
  info_origin_slo: "Herkunft des Namens - slowenisch",
  info_origin_de: "Herkunft des Namens - deutsch",
  info_suppl_notes: "Anmerkungen",
  info_name_cad_mun: "Name im historischen Kataster",
  info_name_church_reg: "Name im Pfarrbuch",
  info_name_official_map: "Name in amtlicher Karte",
  info_old_nr: "alte Hausnummer",
  info_condition: "Zustand des Gebäudes, Beschreibung der Flur",
  info_cad_mun: "Katastralgemeinde im historischen Kataster",
  info_year_cad_mun: "Jahr des historischen Katasters",
  info_year_church_reg: "Jahr des Pfarrbuchs",
  info_official_map: "Daten zur amtlichen Karte",
  info_owner: "Anrede des Hausherren",
  info_where_am_i: "Wo bin ich?",
  info_where_to: "Wohin gehe ich?",
  info_where_from: "Von wem/wo gehe ich",
  info_note_field: "Anmerkungen bei der Erhebung",
  info_note_editing: "Anmerkungen bei der Nachbearbeitung",

  info_category_11: "Bewohntes Gehöft",
  info_category_12: "Verlassenes Gehöft",
  info_category_13: "Verschwundenes Gehöft",
  info_category_14: "Frei stehendes Wasserwirtschaftsgebäude",
  info_category_15: "Frei stehendes Wirtschaftsgebäude - sonstiges",
  info_category_16: "Öffentliches Gebäude",
  info_category_17: "Kirche",
  info_category_18: "Kirchliches Verwaltungsgebäude",
  info_category_21: "Dorf",
  info_category_22: "Markt",
  info_category_23: "Stadt",
  info_category_24: "Teil einer Siedlung",
  info_category_31: "Flur",
  info_category_41: "Quelle",
  info_category_42: "Bach",
  info_category_43: "Wildbach",
  info_category_44: "Fluss",
  info_category_45: "Wasserfall",
  info_category_46: "Sonstiges",
  info_category_47: "Teich",
  info_category_48: "See",
  info_category_49: "Bereich mit zeitweise stehendem Gewässer",
  info_category_51: "Gebiet < 2 km",
  info_category_52: "Gebiet 2–5 km",
  info_category_53: "Gebiet 5–10  km",
  info_category_54: "Gebiet > 10 km",
  info_category_61: "Gipfel",
  info_category_62: "Sattel",
  info_category_63: "Oberflächenerscheinung im Gebirge",
  info_category_71: "Unterirdische Karstform",
  info_category_72: "Karstdepression",
  info_category_73: "Fels, frei stehender Felsblock",
  info_category_81: "Punkförmiges Objekt",
  info_category_82: "Religiöses Objekt",
  info_category_83: "Linienförmiges Objekt",
  info_category_84: "Flächenförmiges Objekt",
  info_category_85: "Markanter Ort",

  info_more: "Mehr Informationen",

  layer_field_names: "flurnamen",
  layer_lines: "Linien",
  layer_point: "Punkte",
  layer_maps: "Hintergrundlayer",
  layer_satellite: "Satellit",
  layer_carto_map: "Karte"
};
