import 'ol/ol.css';
import 'ol-layerswitcher/src/ol-layerswitcher.css';

/* Force fullpage reload on module changes
 * to prevent side effects on the map.
 */
// eslint-disable-next-line no-undef
if (module.hot) {
  // eslint-disable-next-line no-undef
  module.hot.accept(function () {
    location.reload();
  });
}
/* end of hot reload */


import {
  lang_si
} from './lang/lang.si.js';
import {
  lang_de
} from './lang/lang.de.js';
import {
  villageBookmarksSi,
  villageBookmarksDe
} from './village_bookmarks.js';
import {
  searchNames
} from './search_names.js';

// TODO move to appropriate position
import {
  map,
  overlay
} from './map';
import {
  cartoLayer,
  satelliteLayer
} from './layers';
import {
  highlightLayer
} from './highlightLayer';

import Feature from 'ol/Feature.js';
import {
  fromLonLat
} from 'ol/proj.js';


/**
 * Language switch
 */
var dictionary = lang_si;
var villageBookmarks = villageBookmarksSi;

// Function for swapping dictionaries
var set_lang = function (dictionary) {
  $("[data-i18n]").text(function () {
    var key = $(this).data("i18n");
    if (dictionary.hasOwnProperty(key)) {
      return dictionary[key];
    }
  });
  // set tooltip with html
  // https://stackoverflow.com/a/52320953/1504487
  //$('#topic_1').tooltip('dispose').tooltip({title: 'Goodbye'}).tooltip('show')
  $("[data-title-i18n]").attr("title", function () {
    var key = $(this).data("title-i18n");
    if (dictionary.hasOwnProperty(key)) {
      return dictionary[key];
    }
  })
    .attr("data-original-title", function () {
      var key = $(this).data("title-i18n");
      if (dictionary.hasOwnProperty(key)) {
        return dictionary[key];
      }
    })
    .tooltip('hide');
};

var replaceSearchPlaceholder = function () {
  $('#name-search').attr("placeholder", dictionary.placeholder_search);
};

// Swap languages when menu changes
$("#lang-switch").on("click", function () {
  if (dictionary.hasOwnProperty("language")) {
    if (dictionary.language === "slovene") {
      dictionary = lang_de;
      villageBookmarks = villageBookmarksDe;
    } else {
      dictionary = lang_si;
      villageBookmarks = villageBookmarksSi;
    }

    set_lang(dictionary);
    fillBookmarks();
    replaceSearchPlaceholder();
  }
});

set_lang(dictionary);

/**
 * Zoom to village
 */

var getMunicipalitiesContent = function () {
  let content = "";
  for (var i = 0; i < villageBookmarks.length; i++) {
    content += '<a class="dropdown-item dd-item-municipalities" href="#" data-bbox="' + villageBookmarks[i][1] + '">' + villageBookmarks[i][0] + '</a>';
  }

  return content;
};

var getVillagesContent = function (municipality) {
  // find municipality in array and get subarray
  let found = villageBookmarks.find(function (element) {
    return (element[0] === municipality);
  });
  let villagesArray = found[2];

  // create dropdown html
  let content = "";
  for (var i = 0; i < villagesArray.length; i++) {
    let name = villagesArray[i][0];
    let lon = villagesArray[i][1];
    let lat = villagesArray[i][2];
    let zoom = villagesArray[i][3];
    content += '<a class="dropdown-item dd-item-villages" href="#" data-lon="' + lon + '" data-lat="' + lat + '" data-zoom="' + zoom + '">' + name + '</a>';
  }

  return content;
};

var onVillageClick = function () {
  let village = $(this).text();
  let lon = $(this).data("lon");
  let lat = $(this).data("lat");
  let zoom = $(this).data("zoom");

  $("#dd-mb-villages").text(village);

  let view = map.getView();
  view.animate({
    center: fromLonLat([lon, lat])
  });
  view.animate({
    zoom: zoom
  });
};

var fillBookmarks = function () {
  // fill municipalities dropdown
  $("#dd-menu-municipalities").html(getMunicipalitiesContent());

  // fill villages dropdown after selecting a municipality
  $(".dd-item-municipalities").on('click', function () {
    // zoom to municipality
    let bboxString = $(this).data("bbox");
    let bbox = bboxString.split(",");
    let view = map.getView();
    // TODO add animation
    view.fit(bbox, map.getSize());

    // fill villages
    let municipality = $(this).text();
    $("#dd-mb-municipalities").text(municipality);
    $("#dd-mb-villages").text(dictionary.button_villages);

    $("#dd-menu-villages").html(getVillagesContent(municipality));
    // per default the button is disabled, after the first municipality is chosen, we enable the button
    $("#dd-mb-villages").prop('disabled', false);

    // listener for zoom to selected village
    $(".dd-item-villages").on('click', onVillageClick);
  });
};

fillBookmarks();

/**
 * Search Names
 */

var replaceSpecialChars = function (word) {
  let specials = ["ñ", "ҟ", "ә", "", "í", "ŕ", "ë", "ò", "ə", "", "ķ", "ú", "é", "ί", "ô", "ó", "ê", "á", "à", "", "è", "ć"];
  let replacing = ["n", "k", "e", "e", "i", "r", "e", "o", "e", "e", "k", "u", "e", "i", "o", "o", "e", "a", "a", "e", "e", "c"];

  for (var i = 0; i < specials.length; i++) {
    let regexp = new RegExp(specials[i], "g");
    word = word.replace(regexp, replacing[i]);
  }
  return word;

};

// TODO: zoom-To funktion auslagern?
$('#name-search').autoComplete({
  minChars: 3,
  source: function (term, suggest) {
    term = term.toLowerCase();
    var suggestions = [];

    suggestions = searchNames.filter(function (word) {
      let wordDialect = replaceSpecialChars(word[0].toLowerCase());

      return wordDialect.includes(term) || word[1].toLowerCase().includes(term) || word[2].toLowerCase().includes(term);
    });

    suggest(suggestions);
  },
  renderItem: function (item, search) {
    let municipality = item[3];
    let nameDialect = item[0];
    let nameSlo = item[1];
    let nameDe = item[2];
    let lon = item[4];
    let lat = item[5];
    // let classification = item[6];

    let nameResult = $.grep([nameDialect, nameSlo, nameDe], Boolean).join(", ");

    // this is used to highlight the seach val in the results,
    // currently not in use, because of the special chars replacement
    // search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    // var re = new RegExp("(" + search.split(' ').join('|') + ")", "gi");
    // // use with ' + name.replace(re, "<b>$1</b>") + '

    return '<div class="autocomplete-suggestion search-row" data-lon="' + lon + '" data-lat="' + lat + '" data-name="' + name + '" data-val="' + search + '">' + nameResult + ' <span class="search-municipality">' + municipality + '</span></div>';
  },
  onSelect: function (e, term, item) {
    let lon = item.data("lon");
    let lat = item.data("lat");
    let zoom = 18;

    let view = map.getView();
    view.animate({
      center: fromLonLat([lon, lat])
    });
    view.animate({
      zoom: zoom
    });

    $('#name-search').val(item.data('name'));
  }
});

/**
 * Popup
 */

var fillDetailInfo = function (properties) {
  let contentCategories = ["category_key", "name_dialect", "name_slo", "name_de", "origin_slo", "origin_de", "img", "audio", "suppl_notes", "name_cad_mun", "name_church_reg", "name_official_map", "old_nr", "condition", "cad_mun", "year_cad_mun", "year_church_reg", "official_map", "owner", "where_am_i", "where_to", "where_from", "note_field", "note_editing"];

  let infoBody = $('<div>').attr("id", "detail-info-body").addClass('card-body scrollable-detail-info');
  let table = $('<table>').addClass('table detail-info-table');
  let imgTop;

  for (let i = 0; i < contentCategories.length; i++) {
    let tr = $('<tr>').addClass('popup-row');

    if (contentCategories[i] === "audio") {
      if (properties[contentCategories[i]]) {
        let audioTag = '<audio controls> <source src="https://maps.flurnamen.at/uploads/audio/' + properties[contentCategories[i]] + '" type="audio/mpeg"> Your browser does not support the audio element. </audio>';

        tr.append($('<td colspan="2">').html(audioTag));
      }
    } else if (contentCategories[i] === "img") {
      if (properties[contentCategories[i]]) {

        let imgSrc = 'https://maps.flurnamen.at/uploads/img/' + properties[contentCategories[i]];
        // use name_dialect as caption
        let imgCaption = properties[contentCategories[1]];
        let imgTag = '<a class="popup-image" href="' + imgSrc + '" data-fancybox data-caption="' + imgCaption + '"><img class="card-img-top" src="' + imgSrc + '" width="350"></a>';
        imgTop = imgTag;
      }
    } else if (contentCategories[i] === "category_key") {
      tr.append($('<th class="text-right w-50" scope="row" data-i18n="info_category">').text(dictionary.info_category));
      tr.append($('<td class="w-50" data-i18n="info_category_' + properties[contentCategories[i]] + '">').text(dictionary['info_category_' + properties[contentCategories[i]]]));
    } else {
      tr.append($('<th class="text-right w-50" scope="row" data-i18n="info_' + contentCategories[i] + '">').text(dictionary["info_" + contentCategories[i]]));
      tr.append($('<td class="w-50">').text(properties[contentCategories[i]]));
    }

    table.append(tr);
  }

  infoBody.html(table);
  $('#detail-info-container').html(infoBody);

  // add nameDialect as header
  $('<h5>', {
    class: "card-title",
    text: properties.name_dialect || properties.name_slo
  }).prependTo(infoBody);

  // add image if available
  if (imgTop) {
    $('#detail-info-container').prepend(imgTop);
  }

  // add closer x
  $('<a>', {
    id: 'detail-info-closer',
    class: 'detail-info-closer',
    href: '#'
  }).prependTo('#detail-info-container');
};


/**
 * Feature Info
 */

map.on('click', function (evt) {
  var feature = map.forEachFeatureAtPixel(evt.pixel,
    function (feature) {
      return feature;
    });
  if (feature) {
    createFIPopup(evt, feature);
  }
  // else {
  //     var animations = getAnimations(map);
  //     map.beforeRender(animations.panAnimation, animations.zoomAnimation);
  //     map.getView().setResolution(map.getView().getResolution() / 2);
  //     map.getView().setCenter(evt.coordinate);
  //   }
  else {
    // close popup, when clicking somewhere else
    overlay.setPosition(undefined);
  }
});

function createFIPopup(evt, feature) {
  var properties = feature.getProperties();

  let contentHeader = $('<div>', {
    class: 'popup-header'
  });
  let contentTitle = $('<span>', {
    class: 'popup-title',
    text: properties.name_dialect || properties.name_slo
  });
  let contentCategory = $('<span>', {
    class: 'popup-category',
    text: dictionary['info_category_' + properties.category_key]
  });
  contentHeader.append(contentTitle);
  contentHeader.append(contentCategory);

  let nameSloDe = $.grep([properties.name_slo, properties.name_de], Boolean).join(", ");
  let contentContainer = $('<div>', {
    class: 'popup-content-container',
    text: nameSloDe
  });
  $('<hr>').appendTo(contentContainer);

  if (properties.audio) {
    let audioTag = '<audio controls> <source src="https://maps.flurnamen.at/uploads/audio/' + properties.audio + '" type="audio/mpeg"> Your browser does not support the audio element. </audio>';

    contentContainer.append(audioTag);
  }

  if (properties.img) {
    let imgSrc = 'https://maps.flurnamen.at/uploads/img/' + properties.img;
    // use name_dialect as caption
    let imgCaption = properties.name_dialect || properties.name_slo;
    let imgTag = '<a class="popup-image" href="' + imgSrc + '" data-fancybox data-caption="' + imgCaption + '"><div id="popup-image" style="background-image:url(' + imgSrc + ')"></div></a>';

    contentContainer.append(imgTag);
  }
  $('#popup-content').html(contentHeader);
  $('#popup-content').append(contentContainer);

  $('#popup-content').append('<a id="link-detail-info" href="#" data-i18n="info_more">' + dictionary.info_more + '</a>');

  overlay.setPosition(evt.coordinate);

  $('#link-detail-info').off('click');
  $("#detail-info-container").slideUp();


  // bind fancybox to added image
  $('.popup-image').fancybox();

  // fill and show detail info
  $('#link-detail-info').on('click', function () {

    fillDetailInfo(properties);
    $("#detail-info-container").slideDown();

    $('#detail-info-closer').on('click', function () {
      $("#detail-info-container").slideUp();
    });
  });

  /**
   * Add a click handler to hide the popup.
   * @return {boolean} Don't follow the href.
   */
  $('#popup-closer').on('click', function () {
    overlay.setPosition(undefined);
    $('#popup-closer').blur();

    $('#link-detail-info').off('click');
    $("#detail-info-container").slideUp();


    return false;
  });
}

/**
 * Label Highlight
 */

var highlightFeature = function (feature) {

  let textFeature = new Feature({
    geometry: feature.getGeometry(),
    text: feature.get('name_dialect') || feature.get('name_slo'),
    category_key: feature.get("category_key")
  });

  highlightLayer.getSource().addFeature(textFeature);
  highlightLayer.getSource().addFeature(feature);

  return true;
};

map.on('pointermove', function (evt) {
  if (evt.dragging) {
    return;
  }

  // first clear any existing features in the overlay
  highlightLayer.getSource().clear();

  var pixel = map.getEventPixel(evt.originalEvent);
  var hit = map.forEachFeatureAtPixel(pixel, highlightFeature);
  // var hit = map.forEachFeatureAtPixel(pixel, highlightFeature, layerFilter);
  map.getTargetElement().style.cursor = hit ? 'pointer' : '';
});

/**
 * Layer Switch
 */

$('#layer-satellite').on('click', function () {
  map.removeLayer(cartoLayer);
  let layerCollection = map.getLayers();
  layerCollection.insertAt(0, satelliteLayer);
});

$('#layer-carto').on('click', function () {
  map.removeLayer(satelliteLayer);
  let layerCollection = map.getLayers();
  layerCollection.insertAt(0, cartoLayer);
});

/**
 * Buttons
 */
$('#button_download').on("click", function () {
  let target;
  if (dictionary.language_code === "si") {
    target = "http://www.flurnamen.at/home/zemljevidi/skupni-spletni-zemljevid/";
  } else {
    target = "http://www.flurnamen.at/de/home-2/online-karte/gemeinsame-onlinekarte/";
  }
  window.open(target, '_blank');
});

$('#button_feedback').on("click", function () {
  let target;
  if (dictionary.language_code === "si") {
    target = "http://www.flurnamen.at/home/zemljevidi/sodeluj/";
  } else {
    target = "http://www.flurnamen.at/de/home-2/online-karte/mach-mit/";
  }
  window.open(target, '_blank');
});

/**
 * Tooltips
 */
$('[data-toggle="tooltip"]').tooltip({
  html: true,
  trigger: 'hover'
});
