// use for justified textPath
// import 'ol-ext/style/SetTextPath.js';
// import 'ol-ext/render/Cspline.js';

import {
  Vector as VectorLayer
} from 'ol/layer.js';
import VectorSource from 'ol/source/Vector.js';
import {
  WFS,
  GeoJSON
} from 'ol/format.js';
import {
  equalTo as equalToFilter,
  or as orFilter,
  and as andFilter
} from 'ol/format/filter.js';

import {
  textStyles
} from './style/layerStyles';

var getfeatureRequest = function (extent, filter) {
  return new WFS().writeGetFeature({
    srsName: 'EPSG:3857',
    featureNS: 'fluled',
    featurePrefix: 'fluled',
    featureTypes: ['field_names'],
    outputFormat: 'application/json',
    filter: filter,
    geometryName: "the_geom",
    bbox: extent
  });
};

const getCategoriesFilterForResolution = function (resolution) {
  let filters = [];

  if (resolution < 70.0) {
    filters.push(
      equalToFilter('category_key', 21),
      equalToFilter('category_key', 22),
      equalToFilter('category_key', 23)
    );
  }

  if (resolution < 38.1) {
    filters.push(
      equalToFilter('category_key', 53),
      equalToFilter('category_key', 54)
    );
  }

  if (resolution < 19.0) {
    filters.push(
      equalToFilter('category_key', 51),
      equalToFilter('category_key', 52)
    );
  }

  if (resolution < 9.5) {
    filters.push(
      equalToFilter('category_key', 24),
      equalToFilter('category_key', 31),
      equalToFilter('category_key', 41),
      equalToFilter('category_key', 42),
      equalToFilter('category_key', 43),
      equalToFilter('category_key', 44),
      equalToFilter('category_key', 45),
      equalToFilter('category_key', 46),
      equalToFilter('category_key', 47),
      equalToFilter('category_key', 48),
      equalToFilter('category_key', 49),
      equalToFilter('category_key', 61),
      equalToFilter('category_key', 62),
      equalToFilter('category_key', 63),
      equalToFilter('category_key', 71),
      equalToFilter('category_key', 72),
      equalToFilter('category_key', 73),
      equalToFilter('category_key', 81),
      equalToFilter('category_key', 82),
      equalToFilter('category_key', 83),
      equalToFilter('category_key', 84),
      equalToFilter('category_key', 85)
    );
  }

  if (resolution < 4.7) {
    filters.push(
      equalToFilter('category_key', 11),
      equalToFilter('category_key', 12),
      equalToFilter('category_key', 13),
      equalToFilter('category_key', 14),
      equalToFilter('category_key', 15),
      equalToFilter('category_key', 16),
      equalToFilter('category_key', 17),
      equalToFilter('category_key', 18)
    );
  }

  // if (resolution < 2.3) {
  //   filters.push(
  //
  //   );
  // }

  let showInMapFilter = equalToFilter('show_in_map', 1);

  return andFilter(showInMapFilter, orFilter.apply(null, filters));
};

const ledineVectorSource = new VectorSource({
  format: new GeoJSON(),
  loader: function (extent, resolution) {

    this.resolution = resolution;

    let filters = getCategoriesFilterForResolution(resolution);

    let featureRequest = getfeatureRequest(extent, filters);

    $.ajax('https://geoserver.flurnamen.at/geoserver/fluled/wfs', {
      method: 'POST',
      contentType: 'text/plain',
      data: new XMLSerializer().serializeToString(featureRequest)
    }).done(function (response) {
      let features = new GeoJSON().readFeatures(response);
      ledineVectorSource.addFeatures(features);
    });
  },
  // strategy: bboxStrategy
  // https://gis.stackexchange.com/a/237182/8673
  strategy: function (extent, resolution) {
    if (this.resolution && this.resolution != resolution) {
      // this.loadedExtentsRtree_.clear();
      // for now we clear all names, because we do not want to have names
      // from lower resolutions after zooming out
      ledineVectorSource.clear();
    }
    return [extent];
  }
});

const ledineVectorLayer = new VectorLayer({
  visible: true,
  declutter: true,
  maxResolution: 70.0,
  source: ledineVectorSource,
  style: textStyles
});





// use for justified textPath
// ledineVectorLayer.setTextPathStyle(function(f) {
//   return [textStyles(f)];
// });

// ledineVectorLayer.setTextPathStyle(function(f) {
//     return [new Style({
//       text: new Text({
//         text: f.get("name_dialect"),
//         font: "15px Arial",
//         fill: new Fill({
//           color: "#369"
//         }),
//         stroke: new Stroke({
//           color: "#fff",
//           width: 3
//         }),
//         textBaseline: "middle",
//         textAlign: "justify",
//         rotateWithView: false,
//         textOverflow: "hidden",
//         minWidth: 150
//       })
//       // geometry: f.getGeometry().cspline()
//     })];
//   },
//   0
// );



export {
  ledineVectorLayer
};
