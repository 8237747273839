import {
  Vector as VectorLayer
} from 'ol/layer.js';
import VectorSource from 'ol/source/Vector.js';
import Collection from 'ol/Collection.js';
import {
  highlightStyles
} from './style/layerStyles';


const collection = new Collection();
const highlightLayer = new VectorLayer({
  source: new VectorSource({
    features: collection,
    useSpatialIndex: false // optional, might improve performance
  }),
  style: highlightStyles,
  zIndex: 11,
  updateWhileAnimating: true, // optional, for instant visual feedback
  updateWhileInteracting: true // optional, for instant visual feedback
});

export {
  highlightLayer
};
