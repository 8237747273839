export var lang_si = {
  language: "slovene",
  language_code: "si",

  button_lang: "DE/SI",
  button_tooltip_lang: "<b>Slovenščina</b><br/>Deutsch",
  button_municipalities: "Občine",
  button_villages: "Naselja",
  button_tooltip_download: "Prenesi podatke",
  button_tooltip_feedback: "Sodeluj",

  placeholder_search: "Išči",

  info_name_dialect: "Osnovna narečna oblika imena",
  info_name_slo: "Poknjižena oblika imena",
  info_name_de: "Nemška oblika imena",
  info_audio: "Zvočni posnetek narečne izgovorjave",
  info_img: "Fotografija",
  info_category: "Kategorija",
  info_category_key: "Šifra po številčni kategorizaciji",
  info_origin_slo: "Izvor imena - slovensko",
  info_origin_de: "Izvor imena - nemško",
  info_suppl_notes: "opombe",
  info_name_cad_mun: "Zapis imena v zgodovinskem katastru",
  info_name_church_reg: "Zapis hišnega imena v župnijski knjigi",
  info_name_official_map: "Zapis imena na zemljevidu",
  info_old_nr: "Stara hišna št.",
  info_condition: "Stanje domačije, kratek opis ledine",
  info_cad_mun: "Katastrska občina zgodovinskega katastra",
  info_year_cad_mun: "Letnik katastra",
  info_year_church_reg: "Letnik župnijske knjige",
  info_official_map: "Podatek o zemljevidu",
  info_owner: "Nazivanje gospodarja, imenovalnik",
  info_where_am_i: "Kje sem? (mestn.)",
  info_where_to: "Kam grem? (tož.)     ",
  info_where_from: "Od koga/od kod grem? (rod.)",
  info_note_field: "Terenske opombe",
  info_note_editing: "Kabinetne opombe",

  info_category_11: "Naseljena domačija",
  info_category_12: "Zapuščena domačija",
  info_category_13: "Izginula domačija",
  info_category_14: "Samostojni gospodarski vodni objekt",
  info_category_15: "Samostojni gospodarski objekt - ostalo",
  info_category_16: "Posvetni javni objekt",
  info_category_17: "Cerkev",
  info_category_18: "Upravne cerkvene stavbe",
  info_category_21: "Vas",
  info_category_22: "Trg",
  info_category_23: "Mesto",
  info_category_24: "Del naselja",
  info_category_31: "Ledina",
  info_category_41: "Izvir",
  info_category_42: "Potok",
  info_category_43: "Hudournik",
  info_category_44: "Reka",
  info_category_45: "Slap",
  info_category_46: "Ostalo",
  info_category_47: "Ribnik",
  info_category_48: "Jezero",
  info_category_49: "Območje občasne stoječe vode",
  info_category_51: "Krajina < 2 km",
  info_category_52: "Krajina 2–5 km",
  info_category_53: "Krajina 5–10  km",
  info_category_54: "Krajina nad 10 km",
  info_category_61: "Vrh",
  info_category_62: "Sedlo",
  info_category_63: "Površinski pojav v vzpetem svetu",
  info_category_71: "Podzemna kraška oblika",
  info_category_72: "Kraška depresija",
  info_category_73: "Skalni osamelec, skala, balvan",
  info_category_81: "Točkovni antropogeni pojav",
  info_category_82: "Verski antropogeni objekt",
  info_category_83: "Linijski antropogeni pojav",
  info_category_84: "Površinski antropogeni objekt",
  info_category_85: "Markantna, orientacijska točka",

  info_more: "Več informacij",

  layer_field_names: "Ledinska imena",
  layer_lines: "linijski pojavi",
  layer_point: "točkovni pojavi",
  layer_maps: "Zemljevidi",
  layer_satellite: "Satelitske slike",
  layer_carto_map: "Zemljevid"
};
