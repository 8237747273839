import {
  Map,
  View
} from 'ol';

import {
  defaults as defaultControls,
  Attribution
} from 'ol/control.js';

import Overlay from 'ol/Overlay.js';

/**
 * Popup
 */
const overlay = new Overlay({
  element: $('#popup')[0],
  autoPan: true,
  autoPanAnimation: {
    duration: 250
  }
});

/**
 * Attribution
 */
const attribution = new Attribution({
  collapsible: false
});


/**
 * View/Map
 */
const view = new View({
  center: [1579646.1667817698, 5852434.344277433],
  zoom: 11
});

const map = new Map({
  target: 'map',
  // layers: [
  //   cartoLayer,
  //   boundaryLayer,
  //   ledineVectorLayer,
  //   vasiVectorLayer
  // ],
  controls: defaultControls({
    attribution: false
  }).extend([attribution]),
  overlays: [overlay],
  view: view
});

// // dev
// // in browser use: var map = $('#map').data('map');
$('#map').data('map', map);

export {map, view, attribution, overlay};
