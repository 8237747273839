import icons from '../../img/map/*.svg';

import {
  Fill,
  Stroke,
  Style,
  Text,
  Icon
} from 'ol/style.js';

const textStyles = function (feature) {
  let name = feature.get('name_dialect') || feature.get('name_slo');
  let category = feature.get('category_key');

  let geometryType = feature.getGeometry().getType() === 'Point' ? 'point' : 'line';

  let stroke = new Stroke({
    color: "white",
    width: 5
  });

  let fill = new Fill({
    color: "black"
  });

  let fontWeight = 'bold';
  let fontStyle = 'normal';
  let fontSize = '13px';
  let getFont = function () {
    return fontStyle + ' ' + fontWeight + ' ' + fontSize + ' "Open SansB", "Arial Unicode MS", "sans-serif"';
  };

  let textAlign = 'left';
  let offsetX = 15;

  let text = new Text({
    font: getFont(),
    placement: '',
    fill: fill,
    stroke: stroke,
    text: name
  });

  let getIcon = function (icon, size, color) {
    return new Icon({
      src: icon,
      size: size ? [size, size] : [11, 11],
      imgSize: size ? [size, size] : [11, 11],
      crossOrigin: 'anonymous',
      color: color || "#000000"
    });
  };

  let style = new Style({
    text: text
    // image: undefined
  });

  if (category === 21 ||
    category === 22 ||
    category === 23 ||
    category === 24) {

    fontSize = '20px';
    text.setFont(getFont());
    text.setPlacement(geometryType);
    // text.setTextAlign(textAlign);
    // text.setOffsetX(offsetX);
    style.setZIndex(1);

    // TODO: make own icon for each category
  } else if (category === 11 ||
    category === 12 ||
    category === 13) {

    fontSize = '13px';
    text.setFont(getFont());
    style.setImage(getIcon(icons["square-11"], 11, "#ff0000"));
    text.setPlacement(geometryType);
    text.setTextAlign(textAlign);
    text.setOffsetX(offsetX);
    style.setZIndex(10);

  } else if (category === 14) {

    fontSize = '13px';
    text.setFont(getFont());
    style.setImage(getIcon(icons["watermill-15"], 15, "#0000ff"));
    text.setPlacement(geometryType);
    text.setTextAlign(textAlign);
    text.setOffsetX(offsetX);
    style.setZIndex(10);

  } else if (category === 17) {

    fontSize = '13px';
    text.setFont(getFont());
    style.setImage(getIcon(icons["religious-christian-11"], 11));
    text.setPlacement(geometryType);
    text.setTextAlign(textAlign);
    text.setOffsetX(offsetX);
    style.setZIndex(10);

  } else if (category === 15 ||
    category === 16 ||
    category === 18) {

    fontSize = '13px';
    text.setFont(getFont());
    style.setImage(getIcon(icons["square-11"]), 11, "#909493"); // grey
    text.setPlacement(geometryType);
    text.setTextAlign(textAlign);
    text.setOffsetX(offsetX);
    style.setZIndex(10);

  } else if (category === 31 ||
    category === 63 ||
    category === 72 ||
    category === 83
  ) {

    fill.setColor('#8c4b0f');
    fontSize = '13px';
    text.setFont(getFont());
    text.setPlacement(geometryType);
    // text.setTextAlign("justify"); // use for justified textPath
    style.setZIndex(9);

  } else if (category === 41 ||
    category === 45) {

    // TODO: maybe make the icon blue and the font black?
    fill.setColor('#2A659C');
    fontSize = '13px';
    text.setFont(getFont());
    style.setImage(getIcon(icons["waterfall-11"], 11, "#0000ff"));
    text.setPlacement(geometryType);
    text.setTextAlign(textAlign);
    text.setOffsetX(offsetX);
    style.setZIndex(9);

  } else if (category === 42 ||
    category === 43 ||
    category === 44 ||
    category === 46 ||
    category === 47 ||
    category === 48 ||
    category === 49) {

    fill.setColor('#2A659C');
    fontSize = '13px';
    text.setFont(getFont());
    text.setPlacement(geometryType);
    style.setZIndex(9);

  } else if (category === 51 ||
    category === 52 ||
    category === 53 ||
    category === 54) {

    fill.setColor('#2A9C3C');
    fontSize = '18px';
    text.setFont(getFont());
    text.setPlacement(geometryType);
    style.setZIndex(15);

  } else if (category === 61) {

    fontSize = '14px';
    fontWeight = 'normal';
    text.setFont(getFont());
    style.setImage(getIcon(icons["triangle-11"]));
    text.setPlacement(geometryType);
    text.setTextAlign(textAlign);
    text.setOffsetX(offsetX);
    style.setZIndex(9);

  } else if (category === 62) {

    fontSize = '13px';
    fontWeight = 'normal';
    text.setFont(getFont());
    // style.setImage(getIcon("skateboard"));
    text.setPlacement(geometryType);
    text.setTextAlign(textAlign);
    text.setOffsetX(offsetX);
    style.setZIndex(9);

  } else if (category === 71 ||
    category === 73 ||
    category === 81 ||
    category === 84 ||
    category === 85
  ) {

    fontSize = '13px';
    fontWeight = 'normal';
    text.setFont(getFont());
    style.setImage(getIcon(icons["circle-11"]));
    text.setPlacement(geometryType);
    text.setTextAlign(textAlign);
    text.setOffsetX(offsetX);
    style.setZIndex(9);

  } else if (category === 82) {

    fontSize = '13px';
    fontWeight = 'normal';
    text.setFont(getFont());
    style.setImage(getIcon(icons["religious-christian-11"]));
    text.setPlacement(geometryType);
    text.setTextAlign(textAlign);
    text.setOffsetX(offsetX);
    style.setZIndex(9);

  } else {
    return; // no style
  }

  if (category === 85) {
    style.setImage(getIcon(icons["viewpoint-15"], 15));
  } else if (category === 12) {
    style.setImage(getIcon(icons["square-11"], 11, "#FFE651"));
  } else if (category === 13) {
    style.setImage(getIcon(icons["square-11"], 11, "#4C564E"));
  }

  if (!name || name.length == 0) {
    style.setText(undefined);
  }

  return style;
};

const highlightStyles = function (feature) {
  let style = textStyles(feature);
  if (style.getText()) {
    style.getText().getFill().setColor("red");
  }
  return style;
};


export {
  textStyles,
  highlightStyles
};
