import {
  Image as ImageLayer,
  Tile as TileLayer
  // Vector as VectorLayer
} from 'ol/layer.js';
import ImageWMS from 'ol/source/ImageWMS.js';
import XYZ from 'ol/source/XYZ';

import {
  map
} from './map';
import {
  ledineVectorLayer
} from './fieldNameLayers';
import {
  highlightLayer
} from './highlightLayer';

const boundaryLayer = new ImageLayer({
  visible: true,
  source: new ImageWMS({
    attributions: "© <a href='https://creativecommons.org/licenses/by/3.0/at/deed.de'>CC-BY-3.0: Land Kärnten - data.ktn.gv.at</a>",
    url: 'https://geoserver.flurnamen.at/geoserver/fluled/wms',
    params: {
      'LAYERS': 'mun_bound_subset_extended',
      'VERSION': '1.1.1'
    },
    ratio: 1,
    serverType: 'geoserver'
    // crossOrigin: 'anonymous'
  })
});

const borderAtLayer = new ImageLayer({
  visible: true,
  source: new ImageWMS({
    attributions: "© <a href='https://creativecommons.org/licenses/by-sa/2.0/'>CC-BY-3.0: BEV</a>",
    url: 'https://geoserver.flurnamen.at/geoserver/fluled/wms',
    params: {
      'LAYERS': 'stateborder_at_3857',
      'VERSION': '1.1.1'
    },
    ratio: 1,
    serverType: 'geoserver'
    // crossOrigin: 'anonymous'
  })
});

const cartoLayer = new TileLayer({
  type: 'base',
  source: new XYZ({
    attributions: "© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>",
    url: "https://{a-b}.api.mapbox.com/styles/v1/prebm/ck9stpcfv06md1iq7xjcneoh1/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoicHJlYm0iLCJhIjoiY2thaDQ1dG91MGNubzJ5dDk3bnBlc214ZyJ9.cztbDaIg1fsd0_la30eQzw"
  })
});

const satelliteLayer = new TileLayer({
  type: 'base',
  source: new XYZ({
    attributions: "© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>",
    url: "https://{a-b}.api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoicHJlYm0iLCJhIjoiY2thaDQ1dG91MGNubzJ5dDk3bnBlc214ZyJ9.cztbDaIg1fsd0_la30eQzw"
  })
});

map.addLayer(cartoLayer);
map.addLayer(boundaryLayer);
map.addLayer(borderAtLayer);
map.addLayer(ledineVectorLayer);
map.addLayer(highlightLayer);

export {
  boundaryLayer,
  borderAtLayer,
  cartoLayer,
  satelliteLayer
};
